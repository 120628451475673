import { Flex, Grid } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React from 'react';
import '../../App.css';
import './FormikPadrao.css';

function FormikPadrao({ children, ...props }) {
  return (
    <Formik {...props}>
      {props => (
        <Form>
          <Flex
            flexGrow={1}
            id="content"
            justifyContent="center"
            minHeight="100%"
          >
            <Grid
              gridTemplateColumns="repeat(12, 1fr)"
              gap={'10px'}
              mx={6}
              width="100%"
              height="fit-content"
            >
              {children}
            </Grid>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}

export default FormikPadrao;
