import React, { useEffect, useState } from 'react';
import { BsList } from 'react-icons/bs';
import { IoMdNotificationsOutline } from 'react-icons/io';
import './Header.css';

function Header({handleMenuChange}) {
  const [user, setUser] = useState('emaildefault@email.com');
  const emailUser = localStorage.getItem('user');

  useEffect(() => {
    if (emailUser) {
      const { email } = JSON.parse(emailUser);
      setUser(email);
    }
  }, [emailUser]);

  return (
    <div className="header">
      <div className="saudacao">
        <button
            type="button"
            onClick={handleMenuChange}
          >
            <BsList className="iconemenuHeader" />
        </button>
        <h3 className="bomdia">BOM DIA,</h3>
        <h3 className="user">{user}! </h3>
      </div>
      <div className="icones">
        <button
          type="button"
          className="buttonnot"
          // onCclick={}
        >
          <IoMdNotificationsOutline className="notificacao" />
        </button>
        <button
          type="button"
          className="buttonnot"
          // onClick={}
        >
          Usuário
        </button>
      </div>
    </div>
  );
}

export default Header;
